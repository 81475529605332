<template>
  <div class="slsPageBox">
    <img src="@/assets/image/404.png" alt="404">
    <div class="routerBox">
      <router-link :to="{path:'/'}">
        看首页
      </router-link>
      <router-link :to="{path:'/login'}">
        去登录
      </router-link>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.slsPageBox{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  img{
    width: 45.32vw;
  }
  .routerBox{
    font-size: 14px;
    margin-top: 12px;
    a{
      text-decoration: underline;
    }
    a:first-of-type{
      color: #333;
    }
    a:last-of-type{
      color: #0089FF;
      margin-left: 12px;
    }
  }
}
</style>
